// Edit components variables here. Primary color changed as a example.
// You can alter any variable, by giving it new value and removing !default.
// To see Alpaca Theme variables go to:
// vendor/snowdog/theme-frontend-alpaca/Snowdog_Components/components/Atoms/variables/_variables.scss

// Colors
$black-pearl  : #1d1f21;

// Semantic color scheme
$color-primary: $black-pearl;
$categories-list__overflow: visible !default;
$categories-list__max-height: 100% !default;
$product-view__tabs-margin\@medium                          : 14px auto 0;


// Layout - breakpoints
$screen-xs                   : 320px !default;
$screen-s                    : 380px !default;
$screen-m                    : 768px !default;
$screen-l                    : 1155px !default;
$screen-xl                   : 1200px !default;
$screen-xxl                  : 1600px !default;
