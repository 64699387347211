$tab__padding                      : 0 0 $spacer !default;
$tab__border                       : $border-base !default;

$tab__title-display                : block !default;
$tab__title-size                   : 72px !default;
$tab__title-width                  : 100% !default;
$tab__title-max-width\@large       : 440px !default;
$tab__title-flex\@large            : 1 !default;
$tab__title-padding                : $spacer--medium !default;
$tab__title-border                 : $tab__border !default;
$tab__title-border-width           : 0 0 $border-width-base 0 !default;
$tab__title-color                  : $color-secondary !default;
$tab__title-color--active          : $color-primary !default;
$tab__title-background             : $bg-color-base !default;
$tab__title-background--active     : $gray-light !default;
$tab__title-font-family            : $font-family-base !default;
$tab__title-font-size              : $font-size-large !default;
$tab__title-font-weight            : $font-weight-medium !default;
$tab__title-font-weight--active    : $font-weight-bold !default;
$tab__title-text-align             : center !default;
$tab__title-text-transform         : uppercase !default;
$tab__title-transition             : $transition-base !default;
$tab__title-z-index--active        : $z-index-initial !default;
$tab__title-z-index--active\@large : $z-index-low !default;

$tab__content-z-index              : $z-index-lowest !default;
$tab__content-z-index\@medium      : $z-index-low !default;
$tab__content-padding              : 64px 0 !default; 
$tab__content-padding\@medium      : 22px 24px !default;
$tab__content-padding\@large       : 22px 20px !default;
$tab__content-padding\@extra-large : 22px 22px !default;
$tab__content-padding--secondary   : 64px 0 !default;
$tab__content-width                : 100% !default;
$tab__content-line-height          : 32px !default;
$tab__content-border--active       : $tab__title-border !default;
$tab__content-border--active\@large: none !default;
$tab__content-border-width--active : 0 0 $border-width-base 0 !default;

$tab__icon-display                 : block !default;
$tab__icon-display\@large          : none !default;
$tab__icon-position                : absolute !default;
$tab__icon-margin                  : auto !default;
$tab__icon-right                   : $spacer--medium !default;
$tab__icon-fill                    : $color-secondary !default;
$tab__icon-fill--active            : $color-primary !default;
$tab__icon-transform--active       : rotate(180deg) !default;
